import React from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import Seo from "../components/seo"
import moment from "moment"

const SinglePage = ({ data }) => {
  const newsMedia = data.wpgraphql.newsMedia

  return (
    <Layout>
      <Seo
        title={newsMedia.seo.title}
        description={newsMedia.seo.metaDesc}
        canonical={newsMedia.seo.canonical}
      />
      <Helmet>
        <body className="page-pressrelease" />
      </Helmet>
      <section className="container py-5">
        {
          <div className="row">
            <div
              className="my-3 blog-post border-bottom pb-4"
              key={newsMedia.id}
            >
              <div className="d-lg-flex">
                <h1 className="mb-2">{newsMedia.title}</h1>
                <h5
                  className="mb-2 ml-auto font-weight-normal text-lg-right"
                  style={{ minWidth: "150px" }}
                >
                  {moment(newsMedia.date).format("MMMM D, Y")}
                </h5>
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: newsMedia.content }}
                className="mb-auto"
              />
            </div>
          </div>
        }
      </section>
    </Layout>
  )
}
export default SinglePage

export const query = graphql`
  query($databaseId: ID!) {
    wpgraphql {
      newsMedia(id: $databaseId, idType: DATABASE_ID) {
        title
        date
        id
        slug
        content(format: RENDERED)
        excerpt(format: RENDERED)
        seo {
          canonical
          metaDesc
          title
        }
      }
    }
  }
`
